import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KeycloakService } from '@app/security/keycloak/keycloak.service';
import { ReadUser, ResetPassword } from '@app/user-manager/shared/api/authorization/generated';
import { User } from '@app/user-manager/shared/api/authorization/generated/model/user';
import { PasswordUtilsService } from '@shared/utils/password-utils.service';

export interface ChangeUserPasswordDialogData {
    user: User | ReadUser;
}

@Component({
    selector: 'sphere-reset-user-password-dialog',
    templateUrl: './reset-user-password-dialog.component.html',
    styleUrls: ['./reset-user-password-dialog.component.scss'],
    standalone: false
})
export class ResetUserPasswordDialogComponent implements OnInit {
    public passwordForm: UntypedFormGroup;
    public hidePassword: boolean = true;
    public hideConfirmPassword: boolean = true;
    public hideOldPassword: boolean = true;
    public hideOldPasswordField: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<ResetUserPasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ChangeUserPasswordDialogData,
        private security: KeycloakService
    ) {}

    ngOnInit(): void {
        this.hideOldPasswordField = this.security.currentUser.username === this.data.user?.username;
        this.initForm();
    }

    initForm() {
        this.passwordForm = this.fb.group(
            {
                oldPassword: [''],
                password: ['', [Validators.required, Validators.minLength(5), PasswordUtilsService.passwordValidator()]],
                confirmPassword: ['', [Validators.required]]
            },
            {
                validators: this.checkPasswords.bind(this)
            }
        );
        if (this.hideOldPasswordField) {
            this.oldPassword.setValidators([Validators.required]);
        }
    }

    public close(): void {
        this.dialogRef.close();
    }

    public toggleHidePassword(): void {
        this.hidePassword = !this.hidePassword;
    }

    public toggleHideOldPassword(): void {
        this.hideOldPassword = !this.hideOldPassword;
    }

    public toggleHideConfirmPassword(): void {
        this.hideConfirmPassword = !this.hideConfirmPassword;
    }

    public get password(): AbstractControl {
        return this.passwordForm.get('password');
    }

    public get oldPassword(): AbstractControl {
        return this.passwordForm.get('oldPassword');
    }

    public get confirmPassword(): AbstractControl {
        return this.passwordForm.get('confirmPassword');
    }

    public checkPasswords(formGroup: UntypedFormGroup) {
        const { value: password } = formGroup.get('password');
        const { value: confirmPassword } = formGroup.get('confirmPassword');
        return password === confirmPassword ? null : { passwordNotMatch: true };
    }

    public save(): void {
        const password: ResetPassword = {
            newPassword: this.password.value,
            oldPassword: this.oldPassword.value ? this.oldPassword.value : this.password.value
        };

        this.dialogRef.close(password);
    }

    public getErrorMessage(form: AbstractControl): string {
        if (!form.pristine) {
            if (form.hasError('required')) {
                return 'This field is required.';
            }
            if (form.hasError('minlength')) {
                return 'Minimum password length is 5.';
            }
            if (form.hasError('invalidPasswordCharacters')) {
                return form.getError('invalidPasswordCharacters').validCharacters;
            }
            if (form === this.confirmPassword && this.passwordForm.hasError('passwordNotMatch')) {
                return 'Passwords do not match.';
            }
        }
    }
}
